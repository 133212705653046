import * as React from 'react';
import './App.scss';
import '@maersk-global/fonts/maeu/fonts.css';
import '@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px.css';
import '@maersk-global/mds-foundations/css/foundations.css';
import { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTES } from './constants/routes.constant';
import { ThemeProvider } from '@anchor/react-components/dist/lib/components/ThemeProvider';

import AccessManegement from './components/platforms/accessManagement/accessManagement';
import EnvironmentDetails from './components/platforms/environments/environmentsDetails';
const Dashboard = lazy(() => import('../src/components/dashboard/dashboard'));
const Platforms = lazy(() => import('../src/components/platforms/platforms'));
const HealthStatus = lazy(() => import('../src/components/healthstatus/healthstatus'));
const DeployProducts = lazy(
  () => import('../src/components/platforms/environments/addEnvironments')
);

const App = ({ environmentCode, label }) => {
  return (
    <Router>
      <ThemeProvider>
        <Switch>
          <Route path={ROUTES.SignUpDashboard}>
            <Dashboard />
          </Route>
          <Route path={ROUTES.HealthStatus}>
            <HealthStatus />
          </Route>
          <Route path={ROUTES?.Platforms}>
            <Platforms environmentCode={environmentCode} label={label} />
          </Route>
          <Route path={ROUTES.NewEnvironments}>
            <DeployProducts
              viewProgress={undefined}
              setViewProgress={undefined}
            />
          </Route>
          <Route path={ROUTES?.Platforms}>
            <AccessManegement />
          </Route>
          <Route path={ROUTES.EnvironmentDetails}>
            <EnvironmentDetails />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default App;
