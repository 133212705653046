import { capellaService } from '../../../services/capella.service';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENVIRONMENT_TYPE, PLATFORM_NAME, PRODUCT_NAME, REGION_NAME } from "../../../constants/environments.data";
import { ProductExtensionWrapper } from './style.productExtensions';
import { EnvironmentModalWrapper } from '../environments/environments.style';
import { Typography, Box, Button, ReactSelect, Toast } from '@anchor/react-components/dist/lib/components';
import { Modal, TooltipHost } from '@fluentui/react';

const DeployProductExtension = ({ environmentCode }) => {
  const [selectedProduct, setSelectedProduct] = useState(PRODUCT_NAME[0]); // Initialize with null
  const [isDeployButtonDisabled, setIsDeployButtonDisabled] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDataLoadingForProduct, setIsDataLoadingForProduct] = useState(false);
  // const [correlationId, setCorrelationId] = useState(initialCorrelationId);
  const [isDownloading, setIsDownloading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedProductExtensionName, setSelectedProductExtensionName] = useState("");
  const initialProductExtension = localStorage.getItem("productExtensionList");
  const [productExtensionList, setProductExtensionList] = useState([] || JSON.parse(initialProductExtension));
  const [versionsList, setVersionsList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  // Create a state object to manage the selected values
  useEffect(() => {
    const locationsString = `map(object({
      location: string,
      trusted_resource_group: string,
      restricted_resource_group: string,
      key_vault_name: string,
      key_vault_rg: string,
      subnet_resource_group: string,
    }))`;
    
  // Removing newlines and extra spaces
const singleLineString = locationsString.replace(/\s+/g, ' ').trim();
console.log(singleLineString,"single line string");

    console.log(selectedProductExtensionName, "selected product extension name");
    getProductExtensionNameList("fbm", "wms");
    getVersions("fbm-wms-timezone");
    // getVersionsInput ("fbm-wms-timezone", "0.1.5");
  }, []);
  const handlePlatformChange = (newValue) => {
    console.log(newValue.code, "new product extension value");
    setSelectedProductExtensionName(newValue.code);
    if (!newValue.code === null) {
      getVersions(newValue.code);
    }
    // setShowCheckboxes(true);
  }
  const getProductExtensionNameList = (platformCode, productCode) => {
    // setIsDataLoadingForProduct(true);
    capellaService.getBluePrintByScopeAndRepositoryType(platformCode, productCode).then((response) => {
      localStorage.removeItem("productExtensionList");
      setProductExtensionList(response.data);
      localStorage.setItem("productExtensionList", JSON.stringify(response.data));
      console.log(response.data, "product extension list");
    })
  }
  const getVersions = async (blueprintName: string) => {
    // setIsDataLoadingForProduct(true);
    capellaService.getVersionsByBlueprintName(blueprintName).then((response) => {
      setVersionsList(response.data.data);
      console.log(response.data.data, "product extension versions");
    })
  }
  const deployProductExtension = async (e) => {
    setIsDataLoadingForProduct(false);
    setIsDataLoading(true);
    // setCorrelationId(null);
    e.preventDefault();
    const params = {
      "extensionName": "fbm-wms-timezone",
      "region": "northeurope",
      "multiRegion": true,
      "regionConfig": {
        "secondary": "northeurope"
      },
      "isHigher" : false,  
      "geography": "europe",
      "templaterInput": {
        "moduleName": "fbm-wms-test",      
        "moduleSource": "terraform.hosting.maersk.com/capella/fbm-wms-timezone/azurerm",
        "listVars": {
          "timezone": "utc"
        }
      }
    }
    capellaService.deployProductExtension("fbm", environmentCode, "wms", params).then((response) => {
      console.log(response.data.data, "deploy product extension");
    })
  }
  /* const getVersionsInput = async (blueprintName:string, versionsNumber:string) => {
    // setIsDataLoadingForProduct(true);
    capellaService.getBlueprintsInput(blueprintName, versionsNumber).then((response) => {
        console.log(response.data.data, "product extension versions input");
    })
  } */
  const getVersionsInput = async (blueprintName, versionsNumber) => {
    try {
      const response = await capellaService.getBlueprintsInput(blueprintName, versionsNumber);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching versions input:", error);
      return null;
    }
  };
  const handleDownloadVersionsInput = async () => {
    setIsDownloading(true);
    try {
      const versionsInput = await getVersionsInput("fbm-wms-timezone", "0.1.5");
      if (versionsInput !== undefined) {
        // Create a Blob from the response data
        const blob = new Blob([JSON.stringify(versionsInput, null, 2)], { type: "application/json" });
        // Create a link element, set its href attribute to a blob URL, and trigger a click event to download the file
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "versions_input.json";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        console.error("No versions input data available to download.");
      }
    } catch (error) {
      console.error("Error handling versions input:", error);
    }
  };
  /**upload file **/
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };
  
  const handleUpload = async () => {
    if (uploadedFile) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async (event) => {
          try {
            const fileContent = JSON.parse(event.target.result.toString());
            setFileContent(fileContent);
          } catch (error) {
            console.error("Error parsing uploaded file content:", error);
          }
        };
        fileReader.readAsText(uploadedFile);
      } catch (error) {
        console.error("Error reading uploaded file:", error);
      }
    } else {
      console.error("No file uploaded.");
    }
  };
/*   const handleOpenFile = () => {
    if (fileContent) {
      console.log("Opened file:", fileContent);
      // You can implement the logic to handle the opened file content here
    } else {
      console.error("No file to open.");
    }
  }; */
  return (
    <ProductExtensionWrapper >
      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        {/* <Heade/> */}
        <Box>
          <Typography variant="h4">
            Deploy Product Extension for <br /><strong>{environmentCode}</strong> environment.
          </Typography>
          <div className="cap_toolbar_divider"></div>
          <Box className={`cap_addproduct`}>
            <Box className="cap_toolbar_input">
              <ReactSelect
                // className={`react-select margintop-5`}
                closeMenuOnSelect
                fit="medium"
                id="name"
                isClearable
                label="Product Extension Name"
                name="name"
                // value={selectedPlatform.code}        
                onChange={handlePlatformChange}
                options={productExtensionList.map((item) => ({ code: item.moduleName, label: item.moduleName }))}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionUpdate={function noRefCheck() { }}
                orientation="vertical"
                placeholder="Select Product Extension Name"
                required
                suggestType="static"
                tooltipDescription="Please select a product extension name"
                variant="default"
                width={100}
              />
              <ReactSelect
                // className={`react-select margintop-5`}
                closeMenuOnSelect
                fit="medium"
                id="name"
                isClearable
                label="Versions"
                name="versions"
                // value={selectedPlatform.code}        
                onChange={handlePlatformChange}
                options={versionsList.map((item) => ({ code: item, label: item }))}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionUpdate={function noRefCheck() { }}
                orientation="vertical"
                placeholder="Select a version"
                required
                suggestType="static"
                tooltipDescription="Please select a version"
                variant="default"
                width={100}
              />

              <ReactSelect
                className={`react-select margintop-5`}
                closeMenuOnSelect
                errorMessage="This is error message"
                fit="medium"
                // hint="This is hint text"
                hintPosition="left"
                id="primaryRegion"
                isClearable
                label="Primary Region"
                name="primaryRegion"
                // value={[selectedValues.primaryRegion]}
                // onChange={handlePrimaryRegionChange}
                options={REGION_NAME}
                onInputChange={function noRefCheck() { }}
                onMenuClose={function noRefCheck() { }}
                onMenuOpen={function noRefCheck() { }}
                onOptionUpdate={function noRefCheck() { }}
                orientation="vertical"
                // placeholder={selectedPrimaryRegion.label}
                required
                suggestType="static"
                tooltipDescription="Please select region"
                variant="default"
                width={100}
              />
              {isDownloading && <Toast
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit magna aliqua."
                options={{
                  type: 'default'
                }}
                title="Toast Component"
              />}
              <Button
                className="button_download"
                label="Download Versions Input"
                onClick={handleDownloadVersionsInput}
              />
              <Box style={{marginTop:"20px"}}>
              <label htmlFor="file-upload" className="button_upload">Upload File</label>

      <input style={{marginRight:"20px"}}
        id="file-upload"
        type="file"
        accept=".json"
        onChange={handleFileUpload}
      />
      <Button
        className="button_submit"
        label="Submit"
        onClick={handleUpload}
      />
      {fileContent && (
        <div>
          <h3>Uploaded File Content:</h3>
          <pre>{JSON.stringify(fileContent, null, 2)}</pre>
         {/*  <Button
            label="Open File"
            onClick={handleOpenFile}
          /> */}
        </div>
      )}
              </Box>
              <div>
              </div>

              <Button className="button_deploy"
                label="Deploy"
                onClick={() => setDisplayModal(true)}
              // disabled={isDeployButtonDisabled} 
              />
              {displayModal &&
                <div style={{ alignItems: "center", fontSize: "16" }}>
                  <Modal
                    className="cap_modals"
                    isOpen={displayModal}
                    onDismiss={() => setDisplayModal(false)}
                  >
                    <EnvironmentModalWrapper style={{ display: "flex", marginTop: "40px", fontSize: "18", width: "500px", height: "300px", flexDirection: "column", alignItems: "center" }}>
                      <Box className="close-button" onClick={() => setDisplayModal(false)}>x</Box>
                      <p style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>Are you sure you want to deploy?</p>
                      <Box className="cap_toolbar_button" style={{ justifyContent: "center!important", fontSize: "18px", alignItems: "center", display: "inline-flex", marginTop: "80px", justifyItems: "center" }}>
                        <Button className="marginright-10"

                          appearance="default"
                          id="primary"
                          justifyItems="center"
                          label="Ok"
                          name="primary"
                          onClick={(event) => deployProductExtension(event)}
                          title="Button"
                          variant="filled"
                        />
                        <Button className="cap_margintop_10"
                          appearance="default"
                          id="secondary"
                          justifyItems="center"
                          label="Cancel"
                          name="secondary"
                          onClick={() => setDisplayModal(false)}
                          title="Button"
                          variant="secondary"
                        />
                      </Box>
                    </EnvironmentModalWrapper>
                  </Modal>   </div>}
            </Box>
          </Box>
        </Box>
        <Box className="cap_pdtExt_displa">
          {/* I am empty container */}
        </Box>

      </Box>
    </ProductExtensionWrapper>
  );
}
export default DeployProductExtension;