export const PLATFORM_NAME = [
  { label: "Fulfillment by Maersk", code: "fbm" },
  { label: "Managed by Maersk", code: "mbm" },
  { label: "Transported by Maersk", code: "tbm" },
  { label: "Technology Services Engineering", code: "tse" },
];

export const PRODUCT_NAME = [
  { label: "WMS", code: "wms" },
  { label: "Korba", code: "korba" },
  { label: "LF Infor", code: "lfinfor" },
  { label: "TSE Pilot", code: "tsepilot" },
];

export const ENVIRONMENT_TYPE = [
  {
    higher: [
      { label: "POC", code: "poc" },
      { label: "Test", code: "test" },
      { label: "Education", code: "education" },
    ],
  },
  {
    lower: [
      { label: "Pre-production", code: "preproduction" },
      { label: "Production", code: "production" },
    ],
  },
];

export const REGION_NAME = [
  { label: "West Europe", code: "westeurope" },
  { label: "North Europe", code: "northeurope" },
  { label: "West US", code: "westus" },
  { label: "West India", code: "westindia" },
];
export const MULTI_REGION_OPTION =[ 
{ label: "YES", code: "yes" },
{ label: "NO", code: "no" }
];

export const ENVI_NAME = [
  { label: "POC", type:"",code: "poc" },
  { label: "Test",type:"", code: "test" },
  { label: "Education",type:"", code: "education" },
  { label: "Pre-production",type:"", code: "preproduction" },
  { label: "Production", type:"",code: "production" },
];

export const ENVIRONMENT_TYPES = [
  {
    label: "Cdt",
    code: "cdt",
    primaryRegion: "West Europe",
    secondaryRegion: "North Europe",
    view: "View progress",
  },
  {
    label: "Dev",
    code: "dev",
    primaryRegion: "West Europe",
    secondaryRegion: "North Europe",
    view: "View progress",
  },
  {
    label: "Poc",
    code: "dev",
    primaryRegion: "West Europe",
    secondaryRegion: "North Europe",
    view: "View progress",
  },
];
export const REGIONS = [
  { label: "West Europe", code: "westeurope" },
  { label: "North Europe", code: "northeurope" },
  { label: "West US", code: "westus" },
  { label: "West India", code: "westindia" },
];
export  const initialSelectedStates = {
  platform: {
    value: PLATFORM_NAME[0].code,
    label: PLATFORM_NAME[0].label,
  },
  product: {
    value: null,
    label: "Select a product",
  },
  environment: {
    value: ENVIRONMENT_TYPE[0].higher[1]?.code,
    label: ENVIRONMENT_TYPE[0].higher[1]?.label,
  },
  primaryRegion: {
    value: REGION_NAME[0].code,
    label: REGION_NAME[0].label,
  }
};