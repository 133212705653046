
import { IDropdownOption, PersonaInitialsColor, IPersonaProps } from '@fluentui/react';
import moment from 'moment';

export function _dropdownError(dropdownElem: string, hasError: boolean) {
  if (hasError) {
    document.getElementById(dropdownElem).style.borderColor = 'rgb(234, 150, 145)';
  } else {
    document.getElementById(dropdownElem).style.borderColor = 'rgb(211, 211, 211)';
  }
}

export function _createKey() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Colors are based on the office fabric ui PersonaInitialsColor
 * @param initial : user initial
 */
export function _initialsColor(initial: string) {
  let colorNumber = 0;
  switch (initial.charAt(0)) {
    case 'A':
    case 'S':
    case '1':
      {
        colorNumber = 0;
      }
      break;
    case 'B':
    case 'T':
    case '2':
      {
        colorNumber = 1;
      }
      break;
    case 'C':
    case 'U':
    case '3':
      {
        colorNumber = 2;
      }
      break;
    case 'D':
    case 'V':
    case '4':
      {
        colorNumber = 3;
      }
      break;
    case 'E':
    case 'W':
    case '5':
      {
        colorNumber = 4;
      }
      break;
    case 'F':
    case 'X':
    case '6':
      {
        colorNumber = 5;
      }
      break;
    case 'G':
    case 'Y':
    case '7':
      {
        colorNumber = 6;
      }
      break;
    case 'H':
    case 'Z':
    case '8':
      {
        colorNumber = 7;
      }
      break;
    case 'I':
    case '9':
      {
        colorNumber = 8;
      }
      break;
    case 'J':
    case '0':
      {
        colorNumber = 9;
      }
      break;
    case 'K':
      {
        colorNumber = 10;
      }
      break;
    case 'L':
      {
        colorNumber = 12;
      }
      break;
    case 'M':
      {
        colorNumber = 13;
      }
      break;
    case 'N':
      {
        colorNumber = 14;
      }
      break;
    case 'O':
      {
        colorNumber = 16;
      }
      break;
    case 'P':
      {
        colorNumber = 18;
      }
      break;
    case 'Q':
      {
        colorNumber = 22;
      }
      break;
    case 'R':
      {
        colorNumber = 23;
      }
      break;
  }

  return PersonaInitialsColor[colorNumber];
}

export function _setInitials(name: string) {
  return name
    .match(/\b(\w)/g)
    .join('')
    .substring(0, 2)
    .toUpperCase();
}

export function _focusOnError(elemId: string) {
  document.getElementById(elemId).focus();
}

export function _isArrayEqual(oldItems: any, newItems: any): boolean {
  if (!newItems) return false;

  // compare lengths - can save a lot of time
  if (oldItems.length !== newItems.length) return false;

  for (let i = 0, l = oldItems.length; i < l; i++) {
    // Check if we have nested arrays
    if (oldItems[i] instanceof Array && newItems[i] instanceof Array) {
      // recurse into the nested arrays
      if (!_isArrayEqual(oldItems[i], newItems[i])) return false;
    } else if (oldItems[i] !== newItems[i]) {
      return false;
    }
  }
  return true;
}

export function _processErrorData(response: any): any {
  if (response === undefined)
    return ['Unexpected error', 'Please report to the Admiral Support Channel in Teams.', '', ''];
  response.data =
    response.data !== undefined && response.body !== undefined
      ? JSON.parse(response.body)
      : response.data === undefined
      ? isValidJSONString(response.body)
        ? JSON.parse(response.body)
        : response.body
      : response.data;

  let errorMessage = [response.status, '', '', response.data?.operationId];

  const errData = {
    statusCode: response.data?.status || response.data?.Status || response.status,
    message: response.data?.message || response.data?.Message || response.message,
    details: response.data?.details || response.data?.Details || response.details,
    operationId: '', //response.data.operationId || response.data.OperationId,
    data: response.data?.data || response.data?.Data || response.data,
  };
  switch (response.status) {
    case 401:
    case 403:
      {
        errorMessage[1] = 'You have no access to this feature';
      }
      break;
    case 404:
        {
          errorMessage[1] = 'No items found';
        }
        break;
    case 503:
      {
        errorMessage[1] = 'Service is unavailable. Please try again.';
      }
      break;
    case 500:
      {
        errorMessage[1] = 'Something went wrong. Please try again.';
        errorMessage[2] = errData.message;
      }
      break;
    case 502:
      {
        errorMessage[1] =
          'The server encountered a temporary error and could not complete your request.';
      }
      break;
    default:
      {
        errorMessage = new Array();
        errorMessage = [
          errData.statusCode,
          errData.message || response.message,
          errData?.details || errData?.data,
          errData.operationId,
        ];
        if (response.data !== null) {
          if (response.data.Data !== undefined) {
            errorMessage.push(response.data.Data);
          }
        }

        if (Array.isArray(errorMessage[2]) && errorMessage[2].length > 0) {
          let data = errorMessage[2].map((i) => {
            return i.errorMessage;
          });
          errorMessage[1] = `${errorMessage[1]}: ${data.filter((i) => i).join('; ')}`;
        }
      }
      break;
  }

  return errorMessage;
}

export function _processSuccessData(message: string): any {
  return [200, message, undefined, undefined];
}

export function _validateStackName(stackName: any): any {
  let isFormValid = false;
  const validation = {
    nameError: [],
  };

  if (stackName === '') {
    validation.nameError.push('Required');
  }

  if (stackName.length < 4 || stackName.length > 30) {
    validation.nameError.push('Name must be 4-30 characters.');
  }

  const regex = new RegExp('^[a-zA-Z0-9_]{4,30}$');

  if (!regex.test(stackName)) {
    validation.nameError.push(
      'Name must start with a letter or an underscore, and may contain only letters, digits, underscores and dashes.',
    );
  }

  isFormValid = validation.nameError.length > 0 ? false : true;

  if (validation.nameError.length > 0) {
    _focusOnError('name');
  }
  return { formValidation: validation, isFormValid: isFormValid };
}

export function _getDropDownItem(key: any, text: any): IDropdownOption {
  return {
    key: key,
    text: text,
  };
}

export function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export function _checkFormValidation(formValidation: any): boolean {
  let isFormValid = true;
  for (let prop in formValidation) {
    console.log('prop', prop, formValidation[prop].length, isFormValid);
    if (formValidation[prop].length > 0 || !isFormValid) {
      isFormValid = false;
    }

    if (formValidation[prop].length > 0) {
      _focusOnError(prop.split('Error')[0]);
    }
  }

  return isFormValid;
}


export function _toProperCase(word: string) {
  return word.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function _moveObjecttoArray(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function _comparisonValues(items: any) {
  const values = items.map((v) => v.valueOf());
  return items.filter((v, i) => values.indexOf(v.valueOf()) == i);
}

export function _chunkArray(myArray, chunk_size) {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

export function _isGuid(item: string) {
  var regexGuid =
    /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(item);
}

export function isValidJSONString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function numberToDecimal(number: number, decimal: number) {
  return Number(Math.round(Number(number + 'e' + decimal)) + 'e-' + decimal);
}


/**
 * Prepare properties for the telemetry based on the type of the custom metric.
 * For example, product-related metrics should always contain the id of the product as part of the property.
 *
 * @param name The name of the custom metric
 * @param properties The properties to be sent as part of the custom metric
 * @returns Properties including the essential ones
 */
function prepareTelemetryProperties(name: string, properties: { [key: string]: any } = {}): {} {
  if (name.startsWith('Product')) {
    properties['product'] = window.location.pathname.split('/')[2];
  }
  return properties;
}


export function dateToUtc(d: Date): string {
  const ndate = moment(d.toDateString()).format('YYYY-MM-DD'),
    hours = (d.getHours() < 10 ? '0' + d.getHours() : d.getHours().toString()) + ':',
    mins =
      (d.getMinutes() < 10 ? '0' + d.getMinutes().toString() : d.getMinutes().toString()) + ':',
    seconds = d.getSeconds() < 10 ? '0' + d.getSeconds().toString() : d.getSeconds().toString();

  return ndate + 'T' + hours + mins + seconds + '.000Z';
}

export function checkCacheExpiration(expiration: string, setExpiry: string) {
  const timeLimit = Number(setExpiry) * (1000 * 60 * 60 * 24);
  return Date.now() - Number(expiration) > timeLimit;
}

export function checkExpirationByDate(dateExpiration: string) {
  console.log(new Date().toDateString(), new Date(dateExpiration).toDateString());
  return new Date(new Date().toDateString()) > new Date(new Date(dateExpiration).toDateString());
}

/**
 * Validate if the value is null or undefined
 * @params : val - Value to validate
 */
export function isNullOrUndefined(val: any) {
  return val == null;
}
