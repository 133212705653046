import styled from "styled-components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import '../../../styles/var.scss';

export const ProductExtensionWrapper = styled(Box)`
background-color: var(--background);
color: var(--text-primary);
padding: 40px;
.cap_pdtExt_display{
padding: 40px;
margin: 20px;
border-left: 0.5px solid gray;
min-height: 300px;
}
.button_deploy{
    margin-top: 30px;
  }
.button_download{
  margin-top: 10px;
}
.button_upload{
  margin-top: 10px;
  margin:bottom: 20px;
}
`;