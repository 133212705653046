export const userData=[
    {
      accessorKey: 'name',
      header: 'Name',
      id: 'name',
      meta: {
        type: 'custom'
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      id: 'email',
      meta: {
        type: 'custom'
      }
    },
    {
      accessorKey: 'id',
      header: 'Unique ID',
      id: 'id',
      meta: {
        type: 'custom'
      }
    },
    {
      accessorKey: 'role',
      header: 'Role',
      id: 'role',
      meta: {
        type: 'custom'
      }
    }
  ]

  export const usersRoles = [
    {
      id: 'admin',
      isDisabled: true,
      label: 'Administator',
      value: 'admin'
    },
    {
      id: 'contributor',
      label: 'Contributor',
      value: 'contributor'
    },
    {
      id: 'reader',
      label: 'Reader',
      value: 'reader'
    }
  ]