import { ItemType } from '@anchor/react-components/dist/lib/components/Tabs';

export const initialTabState: ItemType[] = [
  {
    active: true,
    icon: 'cloud',
    id: 'environments',
    title: 'Environments',
  },
  {
    active: false,
    icon: 'people',
    id: 'accessManagement',
    title: 'Access Management',
  },
  {
    active: false,
    icon: 'clipboard-list-text',
    id: 'logs',
    title: 'Logs',
  },
  {
    active: false,
    icon: 'play-circle',
    id: 'ansibleruns',
    title: 'Ansible Runs',
  },
];

export const secondaryTabState: any[] = [
  {
    active: true,
    icon: 'user',
    id: 'user',
    title: 'User',
  },
  {
    active: false,
    icon: 'people',
    id: 'team',
    title: 'Teams',
  },
];
export const environmentTabState: any[] = [
  {
    active: true,
    icon: 'product',
    id: 'regions',
    title: 'Regions',
  },
  {
    active: false,
    icon: 'azure',
    id: 'products',
    title: 'Products',
  },
];
export const productTabState: any[] = [
  {
    active: true,
    icon: 'cloud',
    id: 'regions',
    title: 'Regions',
  },
  {
    active: false,
    icon: 'azure',
    id: 'products',
    title: 'Products',
  },
];
