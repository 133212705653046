import * as React from "react";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants/routes.constant";
import { HeaderWrapper } from "./style.header";
import CapellaLogoRgb from "../../assets/capella_logo_rgb.svg";
export function Header(): JSX.Element {
  const history = useHistory();
  return (
    <HeaderWrapper>
    <div className={`cap_header`}>
      <div className={`cap_head`}>
        <div className={`header-title`}>
          <div className={`header-left`}>
            <div className={`cap_head_title`}>
              <img onClick={() => history.push(ROUTES.SignUpDashboard)}
                className={`cap_head_icon`}
                src={CapellaLogoRgb}
              />
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
    </HeaderWrapper>
  );
}

export default Header;
