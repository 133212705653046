import axios, { AxiosInstance } from 'axios';
import { authenticationService } from './shared-services-authentication-service';
import { isNullOrUndefined } from 'src/shared-services-functions/shared-services-functions';


export interface IAxiosService {
  getAxios(useMocks: boolean): AxiosInstance;

}
class AxiosService implements IAxiosService {

  getAxios(useMocks: boolean = false): AxiosInstance {
    const api = axios.create();
    api.defaults.baseURL = window.location.protocol;
    api.defaults.headers = {
      'Content-Type': 'application/json'
    };
    if (useMocks) api.defaults.headers['x-use-mockserver'] = '1';
    api.interceptors.request.use(async (config) => {
      const token = await authenticationService.getAccessToken()
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    }, authError => {
      /* if (appInsightsService.ai != null)
        appInsightsService.ai.trackException({ exception: authError }); */
    });
    return api;
  }

  getAxiosNoAuth(): AxiosInstance {
    const api = axios.create();

    api.defaults.baseURL = window.location.protocol;
    api.defaults.headers = {
      'Content-Type': 'application/json'
    };
    return api;
  }

  getAxiosNoAuthAbsolute(url: string): AxiosInstance {
    const api = axios.create();

    api.defaults.baseURL = url;
    api.defaults.headers = {
      'Content-Type': 'application/json'
    };
    return api;
  }


  getAxiosSample(callType: string, customHeader: Object): AxiosInstance { 
    const api = axios.create();  
    let url = '';

    api.defaults.baseURL = url;
    api.defaults.headers = {
      'Content-Type': 'application/json',
    };
    if(!isNullOrUndefined(customHeader)){
      api.defaults.headers = {...api.defaults.headers, ...customHeader};
    }
    return api;
  }
}


export let axiosService = new AxiosService();
