import { AnsibleRunsInterface } from '@/components/platforms/ansibleRuns/ansibleRunsInterface';
import { axiosService } from 'src/shared-services/shared-services-axios-service';
import { capellaBaseService } from 'src/shared-services/shared-services-base-service';

class CapellaService {
  getBaseUrl(): string {
    return `${capellaBaseService.getBaseUrl()}`;
  }
  getBaseUrlV2(): string {
    return `${capellaBaseService.getBaseUrl()}/v2`;
  }

  getBaseUrlV1(): string {
    return `${capellaBaseService.getBaseUrl()}/v1`;
  }

  // Health Status Capella
  getHealthStatusCapella(): Promise<any> {
    const url = `${this.getBaseUrl()}/healthstatus`;
    const axios = axiosService.getAxios();
    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getLandingZone(
    platformCode: string,
    environmentCode: string,
    regionCode: string
  ): Promise<any> {
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/regions/${regionCode}/landingzones`;
    const axios = axiosService.getAxios();
    return axios({
      method: 'get',
      url: url,
    });
  }
  // POST V2 LZ
  createLandingZoneV2(
    platformCode: string,
    environmentCode: string,
    params: any
  ): Promise<any> {
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/landingzones`;
    const axios = axiosService.getAxios();
    return axios({
      method: 'post',
      url: url,
      params: params,
    });
  }
  //  POST V1 LZ
  createLandingZoneV1(params: any): Promise<any> {
    const url = `${this.getBaseUrlV1()}/landingzones`;
    const axios = axiosService.getAxios();
    return axios({
      method: 'post',
      url: url,
      params: params,
    });
  }
  productOnboarding(params: any): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/product`;
    return axios({
      method: 'post',
      url: url,
      data: params,
    });
  }
  capellaEnvironment(platformCode: string): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  capellaEnvironmentDetails(
    platformCode: string,
    environmentCode: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  getCapellaProducts(
    platformCode: string,
    environmentCode: string,
    regionCode: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/regions/${regionCode}/products`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  getProductExtension(
    platformCode: string,
    environmentCode: string,
    regionCode: string,
    productCode: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/regions/${regionCode}/products/${productCode}/extensions`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  deployProductExtension(
    platformCode: string,
    environmentCode: string,
    productCode: string,
    params: any
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/products/${productCode}/extensions`;
    return axios({
      method: 'post',
      url: url,
      data: params,
    });
  }
  deleteLandingZone(
    platformCode: string,
    environmentCode: string,
    landingZoneId: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/landingzones/${landingZoneId}`;
    return axios({
      method: 'delete',
      url: url,
    });
  }
  deleteProduct(
    platformCode: string,
    environmentCode: string,
    regionCode: string,
    productCode: string,
    id: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/products/${productCode}/deployments/${id}`;
    return axios({
      method: 'delete',
      url: url,
    });
  }
  deleteCapellaProductExtension(
    platformCode: string,
    environmentCode: string,
    productCode: string,
    id: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/products/${productCode}/extensions/${id}`;
    return axios({
      method: 'delete',
      url: url,
    });
  }
  getBluePrintByScopeAndRepositoryType(
    platformCode: string,
    productCode: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    // const baseUrlTestLocal = 'http://localhost:8080/v1';
    const url = `${this.getBaseUrlV1()}}/platforms/${platformCode}/products/${productCode}/blueprints`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  getVersionsByBlueprintName(blueprintName: string): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/blueprints/${blueprintName}`;
    return axios({
      method: 'get',
      url: url,
    });
  }
  getBlueprintsInput(
    blueprintName: string,
    versionsNumber: string
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/blueprints/${blueprintName}/versions/${versionsNumber}/inputs`;
    return axios({
      method: 'get',
      url: url,
    });
  }

  deployConfigManager(
    platformCode: string,
    environmentCode: string,
    regionCode: string,
    params: any
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/regions/${regionCode}/configmanagerdeployments`;
    return axios({
      method: 'post',
      url: url,
      data: params,
    });
  }

  getAnsibleRuns(
    platformCode: string,
    fromDate: string,
    fromTime: string,
    toDate: string,
    toTime: string
  ) {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/fromDate/${fromDate}/fromTime/${fromTime}/toDate/${toDate}/toTime/${toTime}/ansible/runs`;

    return axios.get<AnsibleRunsInterface[]>(url);
  }

  cancelAnsibleRun(
    platformCode: string,
    environmentCode: string,
    region: string,
    params: any
  ): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/environments/${environmentCode}/regions/${region}/configurations/interruptrun`;
    return axios({
      method: 'post',
      url: url,
      data: params,
    });
  }

  getAllLogs(platformCode: string): Promise<any> {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrlV2()}/platforms/${platformCode}/logs`;
    return axios({
      method: 'get',
      url: url,
    });
  }

  async reSendApiRequest(endpoint, method, body = null) {
    const axios = axiosService.getAxios();
    const url = `${this.getBaseUrl()}${endpoint}`;
    const options = {
      url: url,
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options['data'] = JSON.stringify(body);
    }

    return await axios(options);
  }

  getGrafanaLink(corelationId: string, podName: string): string {
    const envCode = capellaBaseService.isProdEnvironment() ? 'p' : 'd';
    return `https://grafana.maersk.io/explore?schemaVersion=1&panes=%7B%22yym%22:%7B%22datasource%22:%22loki%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22expr%22:%22%7Bnamespace%3D%5C%22capella-${envCode}%5C%22,%20pod%3D%5C%22${podName.toLowerCase()}${envCode}-0%5C%22%7D%20%7C%3D%20%60${corelationId}%60%22,%22queryType%22:%22range%22,%22datasource%22:%7B%22type%22:%22loki%22,%22uid%22:%22loki%22%7D,%22editorMode%22:%22builder%22%7D%5D,%22range%22:%7B%22from%22:%22now-6h%22,%22to%22:%22now%22%7D%7D%7D&orgId=1`;
  }
}

export const capellaService = new CapellaService();
