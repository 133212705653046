import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
interface EnvironmentState {
  environmentCode: string;
  setEnvironmentCode: (environmentCode:string) => void;
  }
// define the store
/* export const environmentstore=create<EnvironmentState>(set) => ({
  environmentCode: "",
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  setEnvironmentCode:()=>set((state)=>({environmentCode:state.environmentCode}))
})); */
export const useEnvironmentStore = create<EnvironmentState>((set) => ({
  environmentCode: "",
  setEnvironmentCode: (environmentCode) => set({ environmentCode }),
  }));

export const setEnvironmentCode = (environmentCode: string) => useEnvironmentStore.setState({ environmentCode });

export default useEnvironmentStore;