import styled from "styled-components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import '../../../styles/var.scss';

export const ToolbarWrapper = styled(Box)`
background-color: var(--background);
color: var(--text-primary);
padding: 40px;
.cap_toolbar{

}

.cap_addproduct{
  display: flex;
  flex-direction: row;
  width: 100%
 }
.cap_addproduct > div > div{
  }
  .cap_addproduct > div{
    background-color: transparent;
    border: none;
    padding: 0;
  }
.cap_toolbar > div {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    gap: 20px;
  }
.cap_toolbar_divider{
  

  }
.cap_modals{
    width: 600px;
    height: 300px;
    padding: 20px;
    display: flex;
    justify-content: center;
 }
.cap_toolbar_sort {
    width: 20px;
    height: 20px;
 }
.cap_toolbar_input{
    width: 40%;
}
.cap_toolbar_text{
  width:40%;
  margin-left: 50px;
  }

.button_deploy{
  margin-right: 15px;
}
.margintop-5{
    margin-top: 20px;
}
.input_environemnt{
    margin-top: 30px;
  }
.marginright-10{
  margin-right: 10px!important;
  }
.marginTop10{
  margin-top: -30px;
}
.icon-color{
  color: #3498db;
}
.cap_envi_select{
  width: 200px;
  margin-top: 10px;
}
.cap_envi_select_platform{
  width: 200px;
  margin-top: 10px;
  justify-content: flex-end;
  margin-right: 20px;
}
.cap_sorting_btn{
  width:40px;
  height: 40px;
  margin-left: 35px;
  margin-top: 48px;
  text-align: center;
  border: none;
}
`;

export const EnvironmentCountWrapper = styled(Box)`
  .cap_envi_count {
    
  }
`;

export const H1 = styled.h1`
  font-family: var(--mdt-brand-font--x-small-font-family);
  font-size: ${({ theme }) => `${theme.fontSizes["26"]}`};
  font-weight: lighter;
`;
export const DeployEnvirnomentWrapper = styled(Box)`
background-color: var(--background);
margin-left: 150px;
.title{
  font-size: 20px;
  font-weight:400;
}
.sub-title{
  margin-top: 13px;
  margin-bottom: 20px;
  line-height: 30px;
}
.deploy_envirnoment{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.icon-color{
  color: green;
  margin-top: 15px;
  }
.cap_toolbar_button{
  margin-top:50px;
  margin: 10px
  } 
.margin-top-5{
  margin-top: 5px!important;
  } 
.progress_text{
  margin-top: -26px;
  margin-left: 27px;
}   
`;
export const EnvironmentModalWrapper = styled(Box)`
background-color: var(--background);
display: flex;
.close-button{
  cursor: pointer;
  font-size: 20px;
  display: flex;
  margin-left: 90%;
  margin-top: -20px;
  margin-bottom: 20px;
}
.marginright-10{
  margin-right: 30px!important;
  }
`;
export const EnvironmentCardWrapper = styled(Box)`
background-color: var(--background);
.card_container{
  margin-top: 30px;
  border-radius: 0 0 8px 8px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.card_style{
  width: 350px;
  height: auto;
  min-height: max-content;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.margintop_10{
  margin-top: 10px;
}
.dot_icon_style{
  display: flex;
  justify-content: flex-end;
  margin-top: -9px;
  margin-right: -100px;
  cursore: pointer;
}
`;