import styled from "styled-components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import '../../../styles/var.scss';

export const ConfigManagerWrapper = styled(Box)`
background-color: var(--background);
color: var(--text-primary);
padding: 40px;
.cap_con_display{
padding: 40px;
margin: 20px;
border-left: 0.5px solid gray;
min-height: 300px;
}
.button_deploy{
    margin-top: 30px;
  }
`;
