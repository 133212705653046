import * as React from 'react';
import { useEffect, useState, FC } from 'react';
import Box from '@anchor/react-components/dist/lib/components/Box';
import {
  Button,
  Card,
  Typography,
  Input,
  ReactSelect,
  Icon,
  Breadcrumb,
  Tabs,
} from '@anchor/react-components';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes.constant';
import { capellaService } from '../../../services/capella.service';
import { EnvironmentCardWrapper } from './environments.style';
import Header from '../../../components/shared/header';
import { IProductsProps } from '../../../types/response_types';
import { environmentTabState } from '../tabs/tabs.constants';
import { EnvironmentRoute, ItemType } from '../../../types/platform';
import useIsMounted from '../../../hooks/useIsMountedhook';
import { MessageBar, MessageBarType } from '@fluentui/react';
import useEnvironmentStore from './store';
import DeployProductExtension from '../productExtensions/productExtensions';
import ConfigManager from '../configManager/configManager';

const tabs: any[] = [
  {
    active: false,
    icon: 'productExtensions',
    id: 'productExtensions',
    title: 'Product Extensions',
  },
];
const zoneTabs: any[] = [
  {
    active: false,
    icon: '',
    id: 'configManager',
    title: 'Config Manager',
  },
];
const EnvironmetDetails = () => {
  const environmentCode = useEnvironmentStore(
    (state) => state?.environmentCode
  );
  const history = useHistory();
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [environmentType, setEnvironmentType] = useState([]);
  const [enviDetails, setEnviDetails] = useState<IProductsProps[]>([]);
  const [tabState, setTabState] = useState(environmentTabState);
  const route = useParams<EnvironmentRoute>();
  const [productList, setProductList] = useState([]);
  const [deleteProductExtCId, setDeleteProductExtCId] = useState(null);
  const [showMessageForLandingZone, setShowMessageForLandingZone] =
    useState(false);
  const [showMessageForProductExtension, setShowMessageForProductExtension] =
    useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [productTabState, setProductTabState] = useState(tabs);
  const [selectedTabForConfigManager, setSelectedTabForConfigManager] =
    useState(false);
  const [selectedTabForProduct, setSelectedTabForProduct] = useState(false);
  const [zoneTabState, setZoneTabState] = useState(zoneTabs);
  const [id, setId] = useState(null);
  const handleChangeEnvironment = (selectedOption) => {
    setSelectedEnvironment(selectedOption);
  };

  useEffect(() => {
    capellaEnvironment();
    getLandingZones();
    getProductExtensionList('fbm', environmentCode, 'westeurope', 'wms');
  }, []);
  useEffect(() => {
    console.log('Tabs isMounted', isMounted());
  }, []);
  const onClickTab = (item) => {
    /*   history.push(
             `${ROUTES.EnvironmentDetailsHome}/${item.selectedItemId}`
           );   */
    setTabState(item.updatedTabData);
    setSelectedTabForProduct(false);
    setSelectedTabForConfigManager(false);
  };
  const onClickProductTab = (item) => {
    setSelectedTabForProduct(true);
    setProductTabState(item.updatedTabData);
    setSelectedTabForConfigManager(false);
  };
  const onZoneTab = (item) => {
    setSelectedTabForProduct(false);
    setSelectedTabForConfigManager(true);
    setZoneTabState(item.updatedTabData);
  };

  const getLandingZones = () => {
    capellaService
      .getLandingZone('fbm', environmentCode, 'westeurope')
      .then((response) => {
        const idValue = response.data[0].id;
        setId(idValue);
        console.log(idValue, 'idValue in-->');
        console.log(response.data, 'get landing zones');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteLandingZone = () => {
    capellaService
      .deleteLandingZone('fbm', environmentCode, id)
      .then((response) => {
        console.log(response);
        setShowMessageForLandingZone(true);
        setTimeout(() => {
          setShowMessageForLandingZone(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error.message, 'error response for delete landing zone');
        // Display error message on the screen
        setErrorMessage(
          'Failed to delete Landing Zone. LZ contains products which are not yet deleted'
        );
        // Hide the error message after 5 seconds (adjust the timeout duration as needed)
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      });
  };
  const getProductList = async () => {
    capellaService
      .getCapellaProducts('fbm', environmentCode, 'westeurope')
      .then((response) => {
        setProductList(response.data.data);
        console.log(response.data.data, 'product list');
      });
  };
  const getProductExtensionList = async (
    platformCode: string,
    environmentCode: string,
    regionCode: string,
    productCode: string
  ) => {
    capellaService
      .getProductExtension(
        platformCode,
        environmentCode,
        regionCode,
        productCode
      )
      .then((response) => {
        console.log(response.data, 'product extension list');
      });
  };
  const deleteProduct = async () => {
    const params = {
      platformCode: 'fbm',
      environmentCode: 'poc',
      region: 'westeurope',
      productCode: 'wms',
      geography: 'america',
    };
    capellaService
      .deleteProduct('fbm', environmentCode, 'wms', 'westeurope', '123')
      .then((response) => {
        console.log(response.data, 'delete product extension');
        setShowMessage(true);
        // Set a timeout to hide the message after 2 seconds (2000 milliseconds)
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteProductExtension = async () => {
    capellaService
      .deleteCapellaProductExtension('fbm', environmentCode, 'wms', '123')
      .then((response) => {
        console.log(response.data, 'delete product extension');
        setShowMessageForProductExtension(true);
        // Set a timeout to hide the message after 2 seconds (2000 milliseconds)
        setTimeout(() => {
          setShowMessageForProductExtension(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProductList();
  }, []);

  const capellaEnvironment = () => {
    capellaService
      .capellaEnvironmentDetails('fbm', environmentCode)
      .then((response) => {
        setEnviDetails(response.data);
        console.log(response.data.regions, 'response');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(enviDetails, 'enviDetails');
  // console.log(enviDetails.regions, "enviDetails.regions")
  const sortedEnvironments = [...environmentType]?.sort((a, b) => {
    return sortAscending
      ? a.label?.localeCompare(b.label)
      : b.label?.localeCompare(a.label);
  });
  const filteredEnvironments = selectedEnvironment
    ? environmentType.filter(
        (environment) =>
          environment.environmentCode === selectedEnvironment.value
      )
    : sortedEnvironments;
  const handleViewButton = (event) => {
    history.push(ROUTES.EnvironmentDetails);
    console.log('view button clicked', event.target.innerText);
  };

  return (
    <EnvironmentCardWrapper>
      <Box>
        <Header />
      </Box>
      {errorMessage && (
        <MessageBar
          style={{ marginBottom: '20px' }}
          messageBarType={MessageBarType.error}
        >
          {errorMessage}
        </MessageBar>
      )}
      {showMessageForLandingZone && (
        <MessageBar
          style={{ marginBottom: '20px' }}
          messageBarType={MessageBarType.success}
        >
          Delete Landing Zone is in progress.
        </MessageBar>
      )}
      {showMessage && (
        <MessageBar
          style={{ marginBottom: '20px' }}
          messageBarType={MessageBarType.success}
        >
          Delete Product is in progress.
        </MessageBar>
      )}
      {showMessageForProductExtension && setDeleteProductExtCId !== null && (
        <MessageBar
          style={{ marginBottom: '20px' }}
          messageBarType={MessageBarType.success}
        >
          Delete Product Extension is initiated.
        </MessageBar>
      )}
      <Box className="margintop_10">
        <Breadcrumb
          activeItem="environmentDetails"
          className="breadcrumb-story"
          mainObject={{
            editProfile: {
              breadcrumbLocator: 'home.profile.editProfile',
              label: 'Environments',
              path: '/environments',
            },
            environmentDetails: {
              breadcrumbLocator: 'home.profile.editProfile.details',
              label: 'Environment Details',
              path: '/details',
            },
            home: {
              breadcrumbLocator: 'home',
              label: 'Home',
              path: '/',
            },
            profile: {
              breadcrumbLocator: 'home.profile',
              label: 'Platform',
              path: '/profile',
            },
          }}
          onBreadcrumbClick={function noRefCheck() {}}
          separator="/"
        />
      </Box>
      <Box className="margintop_10">
        <Tabs items={tabState} onClick={onClickTab} fit="medium" />
      </Box>
      {tabState[1].active && (
        <Box className="margintop_10">
          <Tabs
            items={productTabState}
            onClick={onClickProductTab}
            fit="medium"
          />
        </Box>
      )}
      {tabState[0].active && (
        <Box className="margintop_10">
          <Tabs items={zoneTabState} onClick={onZoneTab} fit="medium" />
        </Box>
      )}
      {/* Product List */}
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {/*    <Box style={{ width: "30%", padding: "20px", borderRight: "0.5px solid gray" }}>
                <Filter environmentCode={environmentCode}/>
            </Box> */}
        {selectedTabForConfigManager && (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <ConfigManager environmentCode={environmentCode} />
          </Box>
        )}
        {selectedTabForProduct && (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <DeployProductExtension environmentCode={environmentCode} />
          </Box>
        )}
        <Box className="card_container">
          {!selectedTabForProduct &&
            tabState[1].active &&
            productList?.map((product, index) => (
              <Box className="card_style">
                {/*  Deploy product extensions*/}
                {
                  <Card
                    key={index}
                    body={
                      <Box
                        style={{
                          display: 'flex',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <Box>
                            <Typography variant="h5">
                              Environment:{environmentCode}
                            </Typography>
                            <Typography variant="h6">
                              Product:{' '}
                              {product.length === 0
                                ? `No product available for {environmentCode} environment`
                                : product?.productName}
                            </Typography>
                            <Box>
                              <Button
                                appearance="default"
                                id="primary"
                                label="Delete Product"
                                name="primary"
                                onClick={deleteProduct}
                                title="View"
                                variant="filled"
                              />
                            </Box>
                            <Box style={{ marginTop: '10px' }}>
                              <Button
                                appearance="default"
                                id="primary"
                                label="Delete Product Extension"
                                name="primary"
                                onClick={deleteProductExtension}
                                title="View"
                                variant="filled"
                              />
                            </Box>
                          </Box>
                          <Box></Box>
                        </Box>
                      </Box>
                    }
                    fit="medium"
                    heading=""
                    href=""
                    imagesrc=""
                    imagebackgroundcolor=""
                    imagescalestrength="light"
                    orientation="vertical"
                    padding=""
                    rel=""
                    subHeading=""
                    target=""
                    variant="bordered"
                  />
                }
              </Box>
            ))}

          {/* Landing Zone card */}
          {!selectedTabForConfigManager && tabState[0].active && (
            <Box className="card_container">
              {enviDetails?.map((environment, index) =>
                environment?.regions?.map((rg) => (
                  <Box className="card_style">
                    <Card
                      key={index}
                      body={
                        <Box
                          style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box>
                            <Box>
                              <Typography variant="h5">
                                Environment: {environmentCode}
                              </Typography>
                              <Typography variant="h6">
                                Region: {rg?.name}
                              </Typography>
                              <Box>
                                <Button
                                  appearance="default"
                                  id="primary"
                                  label="Delete Landing Zone"
                                  name="primary"
                                  onClick={deleteLandingZone}
                                  title="View"
                                  variant="filled"
                                />
                              </Box>
                            </Box>
                            <Box></Box>
                          </Box>
                        </Box>
                      }
                      fit="medium"
                      heading=""
                      href=""
                      imagesrc=""
                      imagebackgroundcolor=""
                      imagescalestrength="light"
                      orientation="vertical"
                      padding=""
                      rel=""
                      subHeading=""
                      target=""
                      variant="bordered"
                    />
                  </Box>
                ))
              )}
            </Box>
          )}
        </Box>
      </Box>
    </EnvironmentCardWrapper>
  );
};
export default EnvironmetDetails;
