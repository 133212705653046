import { Suspense } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <Suspense fallback={null}>
    <Router>
      <App environmentCode={''} label={''} />
    </Router>
  </Suspense>,
  document.getElementById('root')
);
