export enum ROUTES {
  SignUpDashboard = "/capella/home",
  Platforms = "/capella/platforms/:platformCode?/:subView?",
  PlatformsHome = "/capella/platforms",
  DisplayEnvirontments="/capella/platforms/test/environments",
  Platform = "/capella/platforms/:platformCode/:subView",
  NewEnvironments = "/capella/platform/addEnvironments",
  AllEnvironments = "/capella/platforms/fulfilment/environments",
  EnvironmentDetails = "/capella/platform/environmentDetails",
  AddUsers ="/capella/platforms/fufilment/accessManagement/users",
  Logs = "/capella/platforms/fufilment/logs",
  ProductList = "/capella/platform/environmentDetails/products",
  EnvironmentDetailsHome="/environmentDetails",
  AnsibleRuns="/ansibleruns",
  HealthStatus="/capella/healthstatus"
}
