import { Box, Tabs, Button, Typography, Table,  Tag, Avatar, ReactSelect, Input } from "@anchor/react-components/dist/lib/components";
import React from "react";
import TableV2 from "@anchor/react-components/dist/lib/components/TableV2";
import { userData } from "../../../constants/accessmanagement.data";
import { useState, useRef } from "react";
import { secondaryTabState } from "../tabs/tabs.constants";
import { Panel, Shimmer, Icon } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import { roles } from "../../../constants/capella.roles";
import { AccessManagenetWrapper, PanelBodyWrapper } from "./style.managementAccess";
const AccessManegement = () => {
  const [tabStateForUser, setTabStateForUser] = useState(secondaryTabState);
  const [filterVisibility, setFilterVisibility] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const filterBarRef = useRef(null)
  const onClickTab = (item) => { }; const [userName, setUserName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const _onInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setUserName(value);
  };
  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
  };
  const _save = () => {
    if (userName && selectedRole) {
      // Create a new user object with the entered data
      const newUser = {
        name: userName,
        // Add other properties based on your userData structure
        role: selectedRole.name,
      };

      // Update the table data
      // You may need to use a state or a function to update your table data
      // For example, modify the userData state or trigger a function to update the table
    }

    // Close the panel after saving
    dismissPanel();
  };

  /**
    * Create the body of the panel
    **/
  const _onRenderBody = () => {
    // const formValidation = formValidation;
    return (
      <div className="panel-container" style={{ padding: "25px" }}>
        <div className="panel-header">
          <div className="panel-header-title-grp">
            <Typography variant="h6"></Typography>
            {/* <img
              className="panel-icon"
              src={require('../../../../../../apps/admiral-web/src/assets/images/user-alt.svg')}
            /> */}
            <Icon
              iconName="Cancel"
              className="icon-cancel panel-icon ms-Button-icon"
              onClick={_hidePanel}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="field-grp">
          <Input
            fit="small"
            id="textInput"
            label="Name"
            maxLength={10}
            onBlur={function noRefCheck() { }}
            onChange={_onInputChange}
            onClear={function noRefCheck() { }}
            onClick={function noRefCheck() { }}
            onFocus={function noRefCheck() { }}
            onIconClick={function noRefCheck() { }}
            onKeyPress={function noRefCheck() { }}
            placeholder="Enter your name"
            required
            type="text"
            value={userName}
            variant="vanity"
          />
        </div>
        <div className="field-grp" style={{ marginTop: "20px" }}>
          <Input
            fit="small"
            id="textInput"
            label="Email"
            maxLength={10}
            onBlur={function noRefCheck() { }}
            onChange={_onInputChange}
            onClear={function noRefCheck() { }}
            onClick={function noRefCheck() { }}
            onFocus={function noRefCheck() { }}
            onIconClick={function noRefCheck() { }}
            onKeyPress={function noRefCheck() { }}
            placeholder="Enter your email"
            required
            type="text"
            value={userName}
            variant="vanity"
          />
        </div>
        <div className="field-grp" style={{ marginTop: "20px" }}>
          <ReactSelect
            className={`react-select margintop-5`}
            closeMenuOnSelect
            errorMessage="This is error message"
            fit="small"
            // hint="This is hint text"
            hintPosition="left"
            id="roles"
            isClearable
            label="Roles"
            name="roles"
            value={selectedRole}
            onChange={handleRoleChange}
            options={roles.map((role) => { role.name = role.name; return role; })}
            onInputChange={function noRefCheck() { }}
            onMenuClose={function noRefCheck() { }}
            onMenuOpen={function noRefCheck() { }}
            onOptionUpdate={function noRefCheck() { }}
            orientation="vertical"
            placeholder="select a role"
            required
            suggestType="static"
            // tooltipDescription="Please select a role"
            variant="default"
            width={100}
          />
        </div>
      </div>
    );
  };

  const _hidePanel = () => {
    console.log("hide panel")
  };

  const _onRenderFooterContent = () => {
    return (
      <PanelBodyWrapper style={{display: "inline-flex", marginTop: "280px", alignItems: "center", padding: "30px" }}>
        <Box>
          <Button
            appearance="default"
            id="primary"
            justifyItems="center"
            label="Save"
            name="primary"
            onClick={_save}
            title="Button"
            variant="filled"
          />
          {/* <div style={{marginLeft:"10px"}}> */}
          <Button
            className="cancel_button"
            appearance="default"
            id="secondary"
            justifyItems="center"
            label="Cancel"
            name="secondary"
            onClick={dismissPanel}
            title="Cancel"
            variant="outlined"
          />
          {/* </div> */}
         
        </Box>
      </PanelBodyWrapper>
    );
  };


  return (
    <AccessManagenetWrapper style={{ marginTop: "30px" }}>
      <Tabs
        items={tabStateForUser}
        onClick={onClickTab}
        fit="medium"
      />
      <Box  >
        <Box style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }} >
          <Typography variant="h6">Add users and configure their roles for the paltforms.</Typography>
          {tabStateForUser[0].active && (
            <Button
              appearance="default"
              fit="small"
              iconPosition="left"
              id="primary"
              justifyItems="center"
              label="Add User"
              icon={"plus"}
              name="primary"
              onClick={openPanel}
              // onClick={() => setFilterVisibility(!filterVisibility)}
              // onClick={onClickAddUser}
              title="Add User"
              variant="filled"
              disabled={false}
              className="cap_add_envi"
            />
          )}
          <Panel
            isOpen={isOpen}
            onDismiss={dismissPanel}
            headerText="Add User"
            onRenderBody={_onRenderBody}
            onRenderFooter={_onRenderFooterContent}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            isLightDismiss={true}
            customWidth="1200px"
          >
          </Panel>
        </Box>
        <TableV2
          columnData={userData}
          defaultData={[
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color1"
                fit="small"
                initials="EM" info={""} />Emma Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>emma@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>EM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Capella API Developer</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color2"
                fit="small"
                initials="GM" info={""} />Gerner Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>gerner@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>GM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Reader</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color4"
                fit="small"
                initials="JM" info={""} />Johannes Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>johannes@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>JM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Contributor</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color3"
                fit="small"
                initials="MM" info={""} />Madrid Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>madrid@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>MM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Contributor</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color1"
                fit="small"
                initials="EM" info={""} />Mary Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>mary@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>MM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Capella Administrator</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color2"
                fit="small"
                initials="EM" info={""} />Svendborg Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>svendborg@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>SM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Contributor</p></div>
            },
            {
              name: <div><p style={{ margin: '0', display: "inline-flex" }}><Avatar
                appearance="color1"
                fit="small"
                initials="EM" info={""} />Tove Maersk</p></div>,
              email: <div><p style={{ margin: '0' }}>tm@maersk.com</p><span style={{ fontSize: '12px' }}></span></div>,
              id: <div><p style={{ margin: '0' }}>TM1</p></div>,
              role: <div><p style={{ margin: '0' }}>Reader</p></div>
            }
          ]}
          gridLine="horizontal"
          onInputChange={function noRefCheck() { }}
          onPageChange={function noRefCheck() { }}
          onPageSizeOptionsChange={function noRefCheck() { }}
          onRowClickCallback={function noRefCheck() { }}
          onRowExpandedCallback={function noRefCheck() { }}
          onRowSelection={function noRefCheck() { }}
          onSorting={function noRefCheck() { }}
        />
      </Box>
    </AccessManagenetWrapper>

  )
}
export default AccessManegement;