// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/@anchor/react-components/dist/lib/css/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background: #f2f2f2;
  --text-primary: #0f0f0f;
  --text-secondary: #4e4e4e;
  --accent: #dfb017;
  --accent-hover: #cea315;
  --border: #1f1e1e;
  --shadow: 7px 15px 13px -4px #00000056;
}

[data-theme=dark] {
  --background:#333333;
  --text-primary: #f2f2f2;
  --text-secondary: #a7a4a4;
  --accent: #6a5acd;
  --accent-hover: #5b4cbe;
  --border: #696969;
  --shadow: 7px 15px 13px -4px #ffffff1b;
}

.white-text {
  color: white !important;
}

body {
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
  transition: all 0.5s ease-in-out;
  overflow-x: hidden;
}

.white-text {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/var.scss","webpack://./src/App.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iBAAA;EACA,uBAAA;EACA,iBAAA;EACA,sCAAA;ACCJ;;ADEA;EACI,oBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iBAAA;EACA,uBAAA;EACA,iBAAA;EACA,sCAAA;ACCJ;;ADCA;EACI,uBAAA;ACEJ;;AArBA;EACI,8CAAA;EACA,qCAAA;EACA,gCAAA;EACA,kBAAA;AAwBJ;;AAtBA;EACI,uBAAA;AAyBJ","sourcesContent":["\n:root {\n    --background: #f2f2f2;\n    --text-primary: #0f0f0f;\n    --text-secondary: #4e4e4e;\n    --accent: #dfb017;\n    --accent-hover: #cea315; \n    --border: #1f1e1e;\n    --shadow: 7px 15px 13px -4px #00000056;\n}\n\n[data-theme='dark'] {\n    --background:#333333;\n    --text-primary: #f2f2f2;\n    --text-secondary: #a7a4a4;\n    --accent: #6a5acd;\n    --accent-hover: #5b4cbe; \n    --border: #696969;\n    --shadow: 7px 15px 13px -4px #ffffff1b;\n}\n.white-text{\n    color: white!important;\n}","@import \"~@anchor/react-components/dist/lib/css/global.css\";\n@import \"../src/styles/var.scss\";\nbody{\n    background-color: var(--background)!important;\n    color: var(--text-primary)!important;\n    transition: all .5s ease-in-out;\n    overflow-x: hidden;\n}\n.white-text {\n    color: white!important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
