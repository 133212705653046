export const roles=[
    {
        "id":1,
        "key":"Capella Admin",
        
    },
    {
        "id":2,
        "name":"Reader",
        
    },
    {

        "id":3,
        "name":"Product Owner",
        
    },
    {
        "id":4,
        "name":"Capella API Developer",
        
    },
    {
        "id":4,
        "name":"Contributor",
        
    },

]