import styled from 'styled-components';
import Box from '@anchor/react-components/dist/lib/components/Box';

export const HeaderWrapper = styled(Box)`
  .cap_head {
    padding: 20px 20px 15px 20px;
    background-color: #ffffff;
    border: transparent;
    margin-bottom: 0;
    box-shadow: var(--mdt-theme-box-shadow-4);
    height: 50px;
  }

  .cap_head_desc {
    padding: 5px 0 0 0 !important;
    span {
      color: #3b4151;
      font-size: var(--mdt-brand-font-size-s);
      font-weight: var(--mdt-brand-font--default-font-weight);
      font-family: var(--mdt-brand-font--x-small-font-family);
    }
  }

  .cap_head_title {
    margin-left: -20px;
    span {
      margin-top: -6px;
      position: absolute;
      font-size: 24px;
    }
  }

  .cap_head_icon {
    position: relative;
    top: 0px;
    height: 50px;
    width: 220px;
    margin-right: 10px;

    padding-top: 0px;
    cursor: pointer;
  }
`;
