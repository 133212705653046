class CapellaBaseService {
  baseUrl = 'https://bffd.maersk-digital.net';
  baseUrlProd = 'https://bffp.maersk-digital.net';

  getBaseUrl(): string {
    if (this.isProdEnvironment()) {
      return this.baseUrlProd;
    } else {
      return this.baseUrl;
    }
  }

  isProdEnvironment(): boolean {
    const url = window.location.origin;
    return url == 'https://capella.maersk.io';
  }
}
export const capellaBaseService = new CapellaBaseService();
