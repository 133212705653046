import { PLATFORM_NAME, REGION_NAME } from "../../../constants/environments.data";
import { Box, Button, Card, LoadingIndicator, ReactSelect, Typography, Input } from "@anchor/react-components/dist/lib/components";
import { Modal } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { ConfigManagerWrapper } from "./style.configManager";
import { capellaService } from "../../../services/capella.service";
interface ConfigType {
  id: string;
    code: string;
    name: string;
    environmentCode: string;
    region: string;
   
}
const ConfigManager = ({ environmentCode }) => {
    const [config, setConfig] = useState<ConfigType[]>([]);
    const [loading, setLoading] = useState(true);
    const [versionNumberInput, setVersionNumberInput] = useState("");
    const [platformCode, setPlatformCode] = useState(PLATFORM_NAME[0].label);
    const [displayModal, setDisplayModal] = useState(false);
    const[selectedRegion, setSelectedRegion]=useState(REGION_NAME[0].label);
 
    console.log("versionNumberInput", versionNumberInput);
    /*   if (loading) {
          return <LoadingIndicator />;
      } */
    useEffect(() => {
        displayConfigManager();
    }, []);
      const displayConfigManager = async () => {
        capellaService.getLandingZone("fbm", environmentCode, "westeurope").then((response) => {
          console.log(response.data, "landing zone");
          setConfig(response.data);
          setLoading(false);
        }
        )}

      const deployConfigManager = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const params = {
           version: versionNumberInput
        }
        capellaService.deployConfigManager("fbm", environmentCode, selectedRegion, params).then((response) => {
            console.log(response.data.data, "deploy product extension");
     })
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setVersionNumberInput(e.target.value);
    }
    const handlePrimaryRegionChange = (newValue) => {
        setSelectedRegion(newValue);
      }
    return (
        <ConfigManagerWrapper>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Box>
                    <div className="cap_toolbar_divider"></div>
                    <Box className={`cap_addproduct`}>
                        <Box className="cap_toolbar_input" style={{marginRight:"40px"}}>
                            <ReactSelect
                                // className={`react-select margintop-5`}
                                closeMenuOnSelect
                                fit="medium"
                                id="platform"
                                // isClearable
                                label="Platform name"
                                name="name"
                                // value={selectedPlatform.code}        
                                // //    onChange={handlePlatformChange}
                                // options={[PLATFORM_NAME.find((item) => item.code === "fbm")]}
                              /*   onInputChange={function noRefCheck() { }}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionUpdate={function noRefCheck() { }} */
                                orientation="vertical"
                                placeholder={platformCode}
                                required
                                suggestType="static"
                                //    tooltipDescription="Please select a platform"
                                variant="default"
                                width={100}
                            />
                            <ReactSelect
                                // className={`react-select margintop-5`}
                                closeMenuOnSelect
                                fit="medium"
                                id="environment"
                                isClearable
                                label="Environment name"
                                name="environment"
                                // value={selectedPlatform.code}        
                                //    onChange={handlePlatformChange}
                                //    options={PLATFORM_NAME}
                                onInputChange={function noRefCheck() { }}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionUpdate={function noRefCheck() { }}
                                orientation="vertical"
                                placeholder={environmentCode}
                                required
                                suggestType="static"
                                tooltipDescription="Please select a platform"
                                variant="default"
                                width={100}
                            />
                            <ReactSelect
                                className={`react-select margintop-5`}
                                closeMenuOnSelect
                                errorMessage="This is error message"
                                fit="medium"
                                // hint="This is hint text"
                                hintPosition="left"
                                id="region"
                                isClearable
                                label="Region"
                                name="region"
                                // value={selectedRegion}

                                onChange={handlePrimaryRegionChange}
                                options={REGION_NAME}
                                // onInputChange={}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionUpdate={function noRefCheck() { }}
                                orientation="vertical"
                                placeholder={selectedRegion}
                                required
                                suggestType="static"
                                tooltipDescription="Please select region"
                                variant="default"
                                width={100}
                            />
                            <Input className="input_environemnt"
                                fit="medium"
                                id="textInput"
                                label="Version Name"
                                maxLength={10}
                                onBlur={function noRefCheck() { }}
                                onChange={handleInputChange}
                                onClear={function noRefCheck() { }}
                                onClick={function noRefCheck() { }}
                                onFocus={function noRefCheck() { }}
                                onIconClick={function noRefCheck() { }}
                                onKeyPress={function noRefCheck() { }}
                                placeholder="Enter Version Name"
                                required
                                tooltipDescription="Please enter a version name"
                                type="text"
                                value={versionNumberInput}
                                variant="default"
                            />
                            <div>
                            </div>
                            <Button className="button_deploy"
                                label="Deploy"
                               onClick={() => setDisplayModal(true)}
                            // disabled={isDeployButtonDisabled} 
                            />
                                {displayModal &&
               <div style={{ alignItems: "center", fontSize: "16" }}>
                 <Modal
                   className="cap_modals"
                   isOpen={displayModal}
                   onDismiss={() => setDisplayModal(false)}
                 >
                   <Box style={{ display: "flex", marginTop: "40px", fontSize: "18", width: "500px", height: "300px", flexDirection: "column", alignItems: "center" }}>
                     <Box className="close-button" onClick={() => setDisplayModal(false)}>x</Box>
                     <p style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>Are you sure you want to deploy?</p>
                     <Box className="cap_toolbar_button" style={{ justifyContent: "center!important", fontSize: "18px", alignItems: "center", display: "inline-flex", marginTop: "80px", justifyItems: "center" }}>
                       <Button className="marginright-10"
                         appearance="default"
                         id="primary"
                         justifyItems="center"
                         label="Ok"
                         name="primary"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => deployConfigManager(event)}
                         title="Button"
                         variant="filled"
                       />
                       <Button className="cap_margintop_10"
                         appearance="default"
                         id="secondary"
                         justifyItems="center"
                         label="Cancel"
                         name="secondary"
                         onClick={() => setDisplayModal(false)}
                         title="Button"
                         variant="secondary"
                       />
                     </Box>
                   </Box>
                 </Modal>   </div>} 
                        </Box>
                    </Box>
                </Box>
                <Box className="cap_con_display">
                <Box className="card_container">
            {config.map((environment, index) => (
                <Box className="card_style">
                    <Card
                        key={index}
                        body={<Box style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                            <Box>
                                <Box >
                                    <Typography variant="h5">Environment: {environment.environmentCode}</Typography>
                                    <Typography variant="h6">Region: {environment.region}</Typography>    
                                    <Typography variant="h6">Landing zone: {environment.name}</Typography>  
                                    {/* <Typography variant="h6">Version: {}</Typography>    */}
                                </Box>
                                <Box>
                                </Box>
                            </Box>
                        </Box>}
                        fit="medium"
                        heading=""
                        href=""
                        imagesrc=""
                        imagebackgroundcolor=""
                        imagescalestrength="light"
                        orientation="vertical"
                        padding=""
                        rel=""
                        subHeading=""
                        target=""
                        variant="bordered"
                    />
                </Box>
            ))}
        </Box>
                </Box>
            </Box>
        </ConfigManagerWrapper>
    );
}
export default ConfigManager;