import styled from "styled-components";
import Box from "@anchor/react-components/dist/lib/components/Box";
import '../../../styles/var.scss';

export const AccessManagenetWrapper = styled(Box)`
background-color: var(--background);
color: var(--text-primary);
    .cap_access_management{ 
        {
        
    }
    `;

    export const PanelBodyWrapper = styled(Box)`
    .cancel_button{
        margin-left: 30px;
    }
        
        `;    